import React from 'react';
import './gallery.css';
import { useState } from 'react';

function importAll(r) {
	let images = {};
  r.keys().forEach((item, index) => { images[index] = r(item);});
	return images
}

const images = importAll(require.context('/Users/quel/Desktop/ethan-sucks-app/public/photos', false, /\.(png|jpe?g|svg)$/));

const Gallery = () =>{
    return (
        <>
            <div className="gallery">
                {
                    Object.values(images).map((value, i) => {
                        return(
                            <div className="photos" key={i}>
                                <img src={value} alt={value} style={{width: '100%'}}/>
                            </div>
                        )
                    })
                } 
            </div>
        </>
    );
  }

  export default Gallery