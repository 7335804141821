import Gallery from './components/Gallery'
import './app.css'

function App() {
  return (
    <>
      <div className='header'>
        <p className="mainHeading">Ethan You Suck</p>
      </div>
      <Gallery/>
      <div className="footer">
        <p style={{fontSize: '20px'}}>(just kidding)</p>
        <p> you're cool i guess...</p>
      </div>
    </>
  );
}

export default App;
